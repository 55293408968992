// Error Styles
.error-container{
    height: 70vh;
    background: linear-gradient(90deg, hsla(194, 72%, 44%, 1) 17%, hsla(219, 69%, 42%, 1) 100%);
    @include flexColumn();

    .error-404{
        font-size: 5rem;
        color: #fff;
    }

    h2{
        color: #fff;
        font-size: 1.2rem;
    }

    .home-button{
        margin: 2rem 0;
        color: #fff;
        font-size: 1rem;
        border-radius: 30px;
        padding: 5px 15px;
        border: solid 2px #fff;
        transition: 1s;

        &:hover{
            background-color: #fff;
            color: #363636;
        }
    }
}